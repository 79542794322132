@mixin tablecolor($color-bg) {
  background-color: $white;
  color: $black;
  --bs-table-color-state: $black;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(#{$color-bg}, 0.1);
  --bs-table-border-color: rgba(#{$color-bg}, 0.05);
  --bs-table-striped-bg: rgba(#{$color-bg}, 0.2);
  --bs-table-active-bg: rgba(#{$color-bg}, 0.2);
  --bs-table-hover-bg: rgba(#{$color-bg}, 0.2);
  &.table-striped > tbody > tr:nth-of-type(odd) > * {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(#{$color-bg}, 0.2);
    color: $black;
  }
  &.table.table-hover > tbody > tr:hover > * {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(#{$color-bg}, 0.4);
    color: $white;
  }
  .table-active {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgb(#{$color-bg});
    color: $white;
  }
}
caption {
  color: $text-muted;
}
.table-active{
  --bs-table-color-state: var(--default-text-color);
}
@mixin tablehead($color-bg) {
  background-color: $white;
  color: var(--bs-table-color);
  --bs-table-color-state: #000;
  --bs-table-color: #{$black};
  --bs-table-bg: rgba(#{$color-bg}, 0.1);
  --bs-table-border-color: rgba(#{$color-bg}, 0.1);
  --bs-table-striped-bg: rgba(#{$color-bg}, 0.5);
  --bs-table-striped-color: #{$black};
  --bs-table-active-bg: rgba(#{$color-bg}, 0.5);
  --bs-table-hover-bg: rgba(#{$color-bg}, 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
@mixin table-color($color-bg) {
  --bs-table-color: #{$black};
  --bs-table-color-state: $black;
  --bs-table-bg: rgba(#{$color-bg}, 0.1);
  --bs-table-border-color: rgba(#{$color-bg}, 0.1);
  --bs-table-striped-bg: rgba(#{$color-bg}, 0.3);
  --bs-table-striped-color: #{$black};
  --bs-table-active-bg: rgba(#{$color-bg}, 0.3);
  --bs-table-active-color: #{$black};
  --bs-table-hover-bg: rgba(#{$color-bg}, 0.3);
  --bs-table-hover-color: #{$black};
  color: #{$black};
  border-color: rgba(#{$color-bg}, 0.1);
  background-color: #{$white};
}
.table {
  color: $text-color;
  border-color: $default-border;
  margin-block-end: 0;
  .checkboxs {
    margin-bottom: 15px;
  }
  tbody {
    tr {
      th {
        font-weight: $font-weight-medium;
      }
    }
  }
  th,
  td {
    padding: 0.75rem;
    vertical-align: middle;
    line-height: 1.462;
    font-size: 0.813rem;
    white-space: nowrap;
  }
  thead tr th {
    font-weight: $font-weight-semibold;
    font-size: 15px;
  }
  &.table-sm > :not(caption) > * > * {
    padding: 0.3rem;
  }
  &.table-dark {
    color: $white-7;
    border-color: rgba($white, 0.1);
  }
  .thead-primary th {
      background-color: $primary;
      color: $white;
  }
  .thead-secondary th {
      background-color: $secondary;
      color: $white;
  }
  .thead-info th {
      background-color: $info;
      color: $white;
  }
  .thead-warning th {
      background-color: $warning;
      color: $white;
  }
  .thead-danger th {
      background-color: $danger;
      color: $white;
  }
  .thead-success th {
      background-color: $success;
      color: $white;
  }
  .thead-dark th {
      background-color: $dark;
      color: $white;
  }
  .thead-light th {
      background-color: $light;
      color: $dark;
  }
  &.table-primary {
    @include tablecolor(var(--primary-rgb));
  }
  &.table-secondary {
    @include tablecolor(var(--secondary-rgb));
  }
  &.table-warning {
    @include tablecolor(var(--warning-rgb));
  }
  &.table-success {
    @include tablecolor(var(--success-rgb));
  }
  &.table-danger {
    @include tablecolor(var(--danger-rgb));
  }
  &.table-info {
    @include tablecolor(var(--info-rgb));
  }
  &.table-light {
    @include tablecolor(var(--light-rgb));
  }
  &.table-dark {
    @include tablecolor(var(--dark-rgb));
  }
  &.table-bordered {
    &.border-primary {
      tbody,
      td,
      tfoot,
      th,
      thead,
      tr {
        border-color: rgba($primary, 0.1);
      }
    }
    &.border-info {
      tbody,
      td,
      tfoot,
      th,
      thead,
      tr {
        border-color: rgba($info, 0.1);
      }
    }
    &.border-secondary {
      tbody,
      td,
      tfoot,
      th,
      thead,
      tr {
        border-color: rgba($secondary, 0.1);
      }
    }
    &.border-warning {
      tbody,
      td,
      tfoot,
      th,
      thead,
      tr {
        border-color: rgba($warning, 0.1);
      }
    }
    &.border-success {
      tbody,
      td,
      tfoot,
      th,
      thead,
      tr {
        border-color: rgba($success, 0.1);
      }
    }
    &.border-danger {
      tbody,
      td,
      tfoot,
      th,
      thead,
      tr {
        border-color: rgba($danger, 0.1);
      }
    }
  }
  &.table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: rgba(var(--dark-rgb),0.0125);
    color: $text-color;
  }
  &.table-striped-columns > :not(caption) > tr > :nth-child(2n) {
    --bs-table-accent-bg: rgba(var(--dark-rgb),0.025);
    color: $text-color;
  }
  tbody.table-group-divider {
    border-top: 1px solid $default-border;
  }
  &.table-hover > tbody > tr:hover > * {
    --bs-table-accent-bg: #{$default-background};
    color: $text-color;
  }
  .table-active {
    --bs-table-accent-bg: #{$light};
    color: $text-color;
  }
  thead {
    &.table-primary {
      @include tablehead(var(--primary-rgb));
    }
    &.table-secondary {
      @include tablehead(var(--secondary-rgb));
    }
    &.table-warning {
      @include tablehead(var(--warning-rgb));
    }
    &.table-success {
      @include tablehead(var(--success-rgb));
    }
    &.table-danger {
      @include tablehead(var(--danger-rgb));
    }
    &.table-info {
      @include tablehead(var(--info-rgb));
    }
    &.table-light {
      @include tablehead(var(--light-rgb));
    }
    &.table-dark {
      @include tablehead(var(--dark-rgb));
    }
  }
}
.table-primary {
  @include table-color(var(--primary-rgb));
}
.table-secondary {
  @include table-color(var(--secondary-rgb));
}
.table-warning {
  @include table-color(var(--warning-rgb));
}
.table-success {
  @include table-color(var(--success-rgb));
}
.table-info {
  @include table-color(var(--info-rgb));
}
.table-danger {
  @include table-color(var(--danger-rgb));
}
.table-dark {
  @include table-color(var(--dark-rgb));
}
.table-light {
  @include table-color(var(--light-rgb));
}
[data-theme-mode="dark"] {
  .table-dark {
    border-color: rgba(0,0,0,0.025);
  }
}
[dir="rtl"] {
  .table {
    margin-inline-end: 1px;
  }
}
.table{
    margin: 0;
    tbody{
        tr{
            td{
                color: $gray-700;
                font-size: $font-size-14; 
                .product-img{
                    img{
                        border: 0;
                    }
                }
                .userslist-img {
                    width:35px;
                    height: 35px;
                    margin-right: 8px;
                    img {
                        border-radius: 0;
                    }
                }
                .userslist-img.bg-img {
                    img {
                        border: 1px solid var(--stroke-table, #E8EBED);
                        background: lightgray 50% / cover no-repeat;
                    }
                }
                a {
                    color: $secondary;
                    font-size: $font-size-14;
                    font-weight: $font-weight-normal;
                    line-height: normal;
                    &.product-img{
                        margin-right: 5px;
                        flex-shrink: 0;
                    }
                    .info-img {
                        margin-right: 8px;
                        width: 16px;
                        height: 16px;
                    }
                    &:hover {
                        color: $primary;
                        i {
                            color: $primary;
                        }
                    }
                }
                .emp-name {
                    line-height: 1;
                    a {
                        color: $secondary;
                    }
                    p {
                        line-height: 2;
                        font-size: $font-size-15;
                        font-weight: $font-weight-medium;
                        color: $text-color;
                    }
                    .role {
                        line-height: unset;
                    }
                }
                .product-imgs{
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    width:40px;
                    height:40px;
                    background: #FFEBD8;
                    font-size: $font-size-14;
                    color: #FF9F43 !important;
                    font-weight: $font-weight-medium;
                    margin-right:10px;
                }
            }
            .data-header {
                font-weight: $font-weight-semibold;
                color: $secondary;
                font-size: $font-size-15;
                border-bottom: none;
            }
            .ref-number {
                color: $purple;
            }
            &.active{
                border-bottom: 0;
                td{
                    font-size: $font-size-18;
                    color: $gray-700;
                    font-weight: $font-weight-semibold;
                    border-bottom: 0;
                }
            }
        }
        .loss-highlight {
            td {
                font-weight: $font-weight-bold;
                color: $secondary;
                font-size: $font-size-base;
                border-top: 1px solid $gray-300;
            }
        }
        .userimgname {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            img {
                margin-right: 8px;
                border-radius: 6px;
                
            }
        }
        .purchase-count {
            td {
                padding-bottom: 20px;
            }
        }
        
    }
    .Expense-list {
       tr {
        td {
            padding: 15px 17px;
            a {
                margin-bottom: 0;
            }
        }
       }
    }
    .sales-list {
        tr {
            td {
                padding: 20px 17px;
            }
        }
    }
    .Expense-list-blk {
        tr {
            td {
                padding: 20px 17px;
            }
        }
    }
        
    
}
.new {
    tbody {
        tr {
            td {
                border-bottom: none;
            }
        }
    }
}
.table-height{
    min-height: 300px;
    @include respond-below(custom991) {
        min-height: auto;
        margin-bottom: 15px;
    }
}
.max-widthauto{
    max-width: 100% !important;
}
.dataTables_filter {
    & + .dataTables_filter {
        display: none;
    }
}
.dataview {
    .dataTables_length,
    .dataTables_info,
    .dataTables_paginate{
        display: none !important;
    }
}
.dataTables_length label:before {
    content: "Showing 10 of 40 Results";
    margin-right: 5px;
}
.dataTables_length {
    float: left;
    font-size: $font-size-12;
}
.productimgname {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    a{
        color: $secondary;
        font-weight: $font-weight-medium;
    }
    .view-product {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        width: 50px;
        height: 50px;
        border-radius: 6px;
        background: #F8F8F8;
    }
}
.input-blocks{
    .calc-no{
        width: 50px !important;
        text-align: center;
    }
}
.table {
    .input-blocks {
        input[type=text] {
            height:40px;
        }
    }
    .scanner-set{
        width:40px;
        height:40px;
    }
}
.card.table-list-card {
    .card-body {
        padding: 0;
    }
    .table-top {
        padding: 24px 24px 0;
    }
    .table-responsive {
        padding: 24px;
        border-top: 1px solid $gray-400;
        .dataTables_wrapper {
            border: 0;
            padding: 0;
        }
    }
    .tabs-set {
        .nav-tabs {
            padding: 24px 24px 0;
            margin-bottom: 0;
        }
    }
}
.table.profit-table {
    thead {
        background-color: #FAFBFE;
        border-radius: 8px 8px 0px 0px;
        tr {
            &:hover {
                background-color: #FAFBFE;
            }
        }

    }
    tbody {
        td {
            border-bottom: 0;
        }
        .profit-table-bg { 
            td {
                color: $secondary;
                font-weight: $font-weight-bold;
                border-top: 1px solid $gray-100;
                background-color: #FAFBFE;
            }
        }
        .table-heading {
            &:hover {
                background-color: $white;
            }
            td {
                color: $secondary;
                font-weight: $font-weight-bold;
            }
        }
    }
} 
.profit-table-top {
    .daterange-wraper {
        margin: 0 10px;
        input {
            padding-left: 35px;
        }
    }
}
table {
    td {
        .shield {
            width: 14px;
            height: 14px;
        }
    }
}
.paysilp-table-border {
    border: 1px solid $gray-100;
    th {
        border-right: 1px solid $gray-100;
        border-left: 1px solid $gray-100;
        padding: 15px;
        font-weight: $font-weight-bold;
        font-size: $font-size-16;
    }
}
.paysilp-table-borders {
    td {
        border-right: 1px solid $gray-100;
        border-left: 1px solid $gray-100;
        padding: 5px 15px;
        color: $text-color;
    }
}
.payslip-border-bottom {
    border: 1px solid $gray-100;
    th {
        padding: 5px 15px;
        font-weight: $font-weight-bold;
        font-size: $font-size-16;
    }
    td {
        color: $text-color;
    }
}
.emp-details {
    margin: 20px 0;
    .pay-slip-salery {
        h6 {
            font-weight: $font-weight-bold !important;
            margin-bottom: 10px;
            font-size: $font-size-18 !important;
        }
    }
    .emp-name-id.pay-slip-salery {
        margin-right: 30px;
    }
}
.slip-logo {
    @include respond-below(custom575) {
        flex-shrink: 0;
        margin-right: 15px;
    }
}
.product-name-slip {
    h4 {
        color: $primary;
        font-weight: $font-weight-bold;
        font-size: $font-size-18;
    }
}
.pay-slip-box {
    .modal-header {
        border-radius: 8px 8px 0 0;
    }
    .custom-modal-body {
        padding: 0;
    }
    .card {
        border: 0;
        border-radius: 0 0 10px 10px;
        .card-body {
            padding: 20px;
            .emp-details.justify-content-start {
                max-width: 760px;
                margin: 20px auto 20px;
            }
        }
    }
}
